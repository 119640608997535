/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-07-26",
    versionChannel: "nightly",
    buildDate: "2024-07-26T00:05:18.750Z",
    commitHash: "a4c6c3592b162a2b093f61c8ad6aabdd8645d561",
};
